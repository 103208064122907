<template>
    <div class="w-100 org_table">
        <div class="d-flex align-items-center justify-content-between">
            <h5 class="mt-4">Organisations</h5>
        </div>
        <div class="mt-2 me-4">
            <EasyDataTable
                v-model:itemsSelected="org_selection"
                :headers="headers"
                :items="items"
                alternating
                show-index
                :rows-items="[10, 25, 50]"
                :rows-per-page="25"
            >
            <template #empty-message>
                <p>Waiting for results...</p>
            </template>
            <template #header-country="">
                <div role="button" @click="set_country_filter()">
                    <span v-show="$store.getters.getFilters['country'].length"><i class="fa-solid fa-filter"></i></span>
                    Country <span v-show="$store.getters.getFilters['country'].length">= {{$store.getters.getFilters['country'][0]}}</span>
                </div>
            </template>
            <template #item-total_amount="item">
                {{item.total_amount?.toLocaleString('nl-NL', {style:'currency', currency: 'EUR', symbol: '€', notation: 'compact'})}}
            </template>
            <template #item-country="item">
                <div class="country_td" @click="set_country_filter(item.country)"><span :class="'me-2 flag:'+item.country"></span>{{item.country}}</div>
            </template>
            </EasyDataTable>
        </div>
    </div>
</template>

<script>
import EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import 'country-flag-icons/3x2/flags.css'
import GraphOptions from './GraphOptions.vue'

export default {
    name: 'DataTable',
    components: {EasyDataTable, GraphOptions},
    computed: {
        items: function() {return this.$store.state.graph_data['leaderboard']},
        org_selection: {
            get() {
                return this.$store.getters.getSelectedOrganisations
            },
            set(value) {
                this.$store.dispatch('update_org_selection', _.map(value, 'organisation_id'))
            },
        }
    },
    mounted: function() {
        let _this = this;
        this.$store.dispatch('get_leaderboard')
    },
    methods:{
        set_country_filter: function(value) {
            this.$store.commit("SET_FILTER", { field: "country", value: [value] });
            this.$store.dispatch('get_leaderboard')
        }
    },
    data: function() {return({
        show: {
            filter: 0,
            searchbox: 0,
        },
        headers: [
            {text: "Organisation", value: "name"},
            {text: "Org ID", value: "organisation_id"},
            {text: "Number of Projects", value: "project_count"},
            {text: "Total partner €", value: "total_amount"},
            {text: "Country", value: "country"},
        ]
    })}
}
</script>

<style>
.find_org_input {
    min-width:250px;    
}
.selected {
    font-weight:500;
}
.selected td {
    background-color: gold!important;
}
.flag\:UK {
background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 513 342%27%3e%3cg fill=%27white%27%3e%3cpath d=%27M0 0h513v341.3H0V0z%27/%3e%3cpath d=%27M311.7 230 513 341.3v-31.5L369.3 230h-57.6zM200.3 111.3 0 0v31.5l143.7 79.8h56.6z%27/%3e%3c/g%3e%3cpath d=%27M393.8 230 513 295.7V230H393.8zm-82.1 0L513 341.3v-31.5L369.3 230h-57.6zm146.9 111.3-147-81.7v81.7h147zM90.3 230 0 280.2V230h90.3zm110 14.2v97.2H25.5l174.8-97.2zm-82.1-132.9L0 45.6v65.7h118.2zm82.1 0L0 0v31.5l143.7 79.8h56.6zM53.4 0l147 81.7V0h-147zm368.3 111.3L513 61.1v50.2h-91.3zm-110-14.2V0h174.9L311.7 97.1z%27 fill=%27%230052B4%27/%3e%3cg fill=%27%23D80027%27%3e%3cpath d=%27M288 0h-64v138.7H0v64h224v138.7h64V202.7h224v-64H288V0z%27/%3e%3cpath d=%27M311.7 230 513 341.3v-31.5L369.3 230h-57.6zm-168 0L0 309.9v31.5L200.3 230h-56.6zm56.6-118.7L0 0v31.5l143.7 79.8h56.6zm168 0L513 31.5V0L311.7 111.3h56.6z%27/%3e%3c/g%3e%3c/svg%3e");
}
.flag\:EL {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 513 342%27%3e%3cpath fill=%27white%27 d=%27M0 0h513v342H0z%27/%3e%3cg fill=%27%230d5eaf%27%3e%3cpath d=%27M0 0h513v38H0zm0 76h513v38H0zm0 76h513v38H0zm0 76h513v38H0zm0 76h513v38H0z%27/%3e%3cpath d=%27M0 0h190v190H0z%27/%3e%3c/g%3e%3cg fill=%27white%27%3e%3cpath d=%27M0 76h190v38H0z%27/%3e%3cpath d=%27M76 0h38v190H76z%27/%3e%3c/g%3e%3c/svg%3e");
}
td:hover .country_td {
    text-decoration:underline;
}
td:hover .organisation_id {
    text-decoration:underline;
}
</style>